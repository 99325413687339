import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

const colorInfo = [
    {
        link: 'https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf',
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/DIrectorios%2FSW-1.jpg?alt=media&token=e4d94a47-3fbf-4285-b5c4-0b1735a40525',
    },
    {
        link: 'https://www.behr.com/consumer/colors/paint/explore',
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/DIrectorios%2Fbehr-1.jpg?alt=media&token=4a9d2262-758e-4483-8ecf-4712f1158a54',
    },
    {
        link: 'https://www.benjaminmoore.com/en-us/color-overview/color-palettes',
        image: 'https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/DIrectorios%2Fbjm-1.jpg?alt=media&token=4eac15c4-fb10-4e58-9008-3b503abe7692',
    },
]


const Paletacolor = () => {

    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='w-4/5 mx-auto mt-10 py-10 border-t-[10px] border-[#FF010B]'>
            <h2 className='capitalize text-center' > Choose your favorite color through the following color palettes</h2>
            <div className='flex flex-wrap justify-center items-center gap-8 py-10'>
                {
                    rpdata?.brandingExtra?.map((item, index) => {
                        return (
                            <a href={item.link} target="_blank" rel="noreferrer" key={index}>
                                <img src={item.img} alt='no found' />
                            </a>
                        )
                    })
                }


            </div>
        </div>

    )
}


export default Paletacolor;