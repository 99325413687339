import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import imgDots from '../../assets/image/dots.png'
import { ButtonContent } from '../global/boton/ButtonContent';

const BlockHomeDesignOne = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bg-[#00A0E6] w-full py-36'>
            <div className='w-4/5 mx-auto block lg:flex '>
                <div className='w-full lg:w-[60%] bg-white pt-20 pb-[150px] lg:py-10 px-5 md:pl-14 md:pr-40 flex flex-col justify-center text-center md:text-start'>
                    <h2 className='pb-5'>{rpdata?.dbSlogan?.[3]?.slogan}</h2>
                    <p className='pb-5'>{rpdata?.dbHome?.[1]?.text}</p>
                    <div>
                        <ButtonContent />
                    </div>
                </div>
                <div className='w-[80%] lg:w-[40%] bg-[#3B5997] ml-auto lg:ml-0 -my-20 lg:-my-10 h-[400px] md:h-[100vh] pt-[80px]'>
                    <div className='relative '>
                        <img
                            src={rpdata?.stock?.[12]}
                            alt='no found'
                            className='relative z-10 w-[90%] h-[300px] md:h-[70vh] -ml-10 object-cover'
                        />
                        <img
                            src={imgDots}
                            alt='no found'
                            className='absolute -bottom-10 -left-20 w-[80%] h-[90%]'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockHomeDesignOne;