import React, { useContext } from 'react';
import { VscDebugBreakpointData } from 'react-icons/vsc';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';


const BlockOne = ({ title, text, listsAbout, image }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bg-[#efefef] py-[150px]'>
            <div className='w-[95%] md:w-4/5 mx-auto'>
                <div className='border-[15px] border-[#3B5997] flex flex-col-reverse md:flex-row px-5 md:px-10'>
                    <div className='w-full md:w-1/2 bg-white mr-0 -mb-20 mt-5 md:mr-2 md:-my-20 px-10 py-12 text-center md:text-start'>
                        <h2 className='pb-3'>{title}</h2>
                        <p className='pb-3'>{text}</p>
                        {
                            listsAbout ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {
                                        rpdata?.dbAbout?.[0].list.length > 1 ?
                                            rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                return (

                                                    <li key={index} className="py-2 flex items-center">
                                                        <VscDebugBreakpointData />
                                                        <span className="pl-2">{item}</span>
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                                : null
                        }

                        <div>
                            <ButtonContent />
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 ml-0 -mt-20 mb-0 md:ml-2 md:-my-20'>
                        <img
                            src={image}
                            alt='no found'
                            className='w-full h-[300px] md:h-full object-cover'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default BlockOne;